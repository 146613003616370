<template>
  <div>
    <h1 class="text-white bold-text text-4xl mt-12 w-3/4 leading-tight">
      {{ $t("text.landing.title") }}
    </h1>
    <p class="mt-4 text-white">
      {{ $t("text.landing.text") }}
    </p>
    <img
      src="../assets/landing_badger.png"
      alt="landing badger"
      class="mt-10 w-full"
    />
  </div>
</template>

<script setup lang="ts"></script>
